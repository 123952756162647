import React from "react"
import SEO from "components/Seo"
import BackgroundImage from "gatsby-background-image"
import classNames from "classnames/bind"
import Contacts from "components/Contacts"
import PageHeader from "components/PageHeader"
import styles from "./contacts.module.css"
import { motion } from "framer-motion"
import SideGraphic from "images/side-graphic.svg"
import Fb from "images/contacts/fb.svg"
import In from "images/contacts/in.svg"
const cx = classNames.bind(styles)
export const statusVariants = {
  closed: {
    // transition: {
    //   staggerChildren: 0.05,
    //   staggerDirection: -1,
    // },
  },
  open: {
    transition: {
      // staggerDirection: 1,
      // when: "beforeChildren",
      staggerChildren: 0.07,
      delayChildren: 0.6,
    },
  },
}
const variants = {
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
}

const ContactsPage = ({ data }) => {
  const background = data.sanityContacts.mainImage.asset.fluid

  return (
    <>
      <SEO title={data.sanityContacts.title} />
      <div className={cx("wrapper")}>
        <SideGraphic className={cx("graphic")} />
        <div className={cx("background-wrapper")}>
          <BackgroundImage
            className={cx("background")}
            preserveStackingContext
            Tag="div"
            fluid={background}
            backgroundColor={`#fff`}
            style={{
              backgroundPosition: "center top",
              backgroundSize: "100% auto",
            }}
          />
        </div>
        <div className={cx("content")}>
          <div className={cx("header-wrap")}>
            <PageHeader className={cx("header")}>
              {data.sanityContacts.title}
            </PageHeader>
          </div>

          <motion.div layout className={cx("contacts-content")}>
            <motion.div
              className={cx("contacts")}
              variants={statusVariants}
              initial="closed"
              animate="open"
              exit="closed"
            >
              {data.sanityGlobalSettings?.email && (
                <motion.div
                  variants={variants}
                  className={cx("contacts-itemWrap")}
                >
                  <div className={cx("contacts-itemLabel")}>El. paštas:</div>
                  <a
                    href={`mailto:${data.sanityGlobalSettings?.email}`}
                    target="_blank"
                    rel="noreferrer"
                    className={cx("contacts-item")}
                  >
                    {data.sanityGlobalSettings.email}
                  </a>
                </motion.div>
              )}
              {data.sanityGlobalSettings?.phone && (
                <motion.div
                  variants={variants}
                  className={cx("contacts-itemWrap")}
                >
                  <div className={cx("contacts-itemLabel")}>
                    Telefono numeris:
                  </div>
                  <a
                    href={`tel:${data.sanityGlobalSettings?.phone}`}
                    rel="noreferrer"
                    target="_blank"
                    className={cx("contacts-item")}
                  >
                    {data.sanityGlobalSettings.phone}
                  </a>
                </motion.div>
              )}
              {data.sanityGlobalSettings.address && (
                <motion.div
                  variants={variants}
                  className={cx("contacts-itemWrap")}
                >
                  <div className={cx("contacts-itemLabel")}>Adresas:</div>
                  <span className={cx("contacts-item")}>
                    {data.sanityGlobalSettings.address}
                  </span>
                </motion.div>
              )}
              <motion.div
                variants={variants}
                className={cx("contacts-itemWrap", "-social")}
              >
                {data.sanityGlobalSettings.facebook && (
                  <a
                    href={`${data.sanityGlobalSettings.facebook}`}
                    rel="noreferrer"
                    target="_blank"
                    className={cx("contacts-item")}
                  >
                    <Fb />
                  </a>
                )}
                {data.sanityGlobalSettings.instagram && (
                  <a
                    href={`${data.sanityGlobalSettings.instagram}`}
                    rel="noreferrer"
                    target="_blank"
                    className={cx("contacts-item")}
                  >
                    <In />
                  </a>
                )}
              </motion.div>
            </motion.div>
            <Contacts className={cx("form")} />
          </motion.div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    sanityGlobalSettings {
      email
      phone
      address
      instagram
      facebook
    }

    sanityContacts {
      id
      title
      mainImage {
        asset {
          url
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

export default ContactsPage
