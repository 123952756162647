import React from "react"
import { motion } from "framer-motion"

export const FormItem = ({ children }) => {
  const variants = {
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  }
  return <motion.div variants={variants}>{children}</motion.div>
}
