import * as React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const Button = ({ onClick, children, type }) => (
  <div className={cx("wrapper")}>
    <button onClick={onClick} className={cx("button")} type={type ?? "button"}>
      {children}
    </button>
  </div>
)

export default Button
