import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "../styles.module.css"

const cx = classNames.bind(styles)

function Input({
  name,
  placeholder,
  type,
  bind,
  label,
  onInvalid,
  onInput,
  textarea,
  required = false,
}) {
  return (
    <label htmlFor={name} className={cx("input-wrap")}>
      {textarea ? (
        <textarea
          id={name}
          name={name}
          placeholder={placeholder}
          required
          onInvalid={onInvalid}
          onInput={onInput}
          className={cx("input-area", "-textarea")}
          {...bind}
        ></textarea>
      ) : (
        <input
          id={name}
          name={name}
          placeholder={placeholder}
          className={cx("input-area")}
          type={type ?? "text"}
          required={required}
          onInvalid={onInvalid}
          onInput={onInput}
          {...bind}
        />
      )}
      <span className={cx("input-title")}>{label}</span>
    </label>
  )
}

Input.propTypes = {}

export default Input
