import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "../styles.module.css"
import { AnimateSharedLayout, motion } from "framer-motion"

const cx = classNames.bind(styles)

function Radio({ name, label, choices, defaultChoice, required = false }) {
  const [currentChoice, setCurrectChoice] = React.useState(defaultChoice)

  const handleChoice = e => {
    setCurrectChoice(e.target.value)
  }

  const variants = {
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  }

  return (
    <label htmlFor={name} className={cx("input-wrap", "input-wrap--radio")}>
      <span className={cx("input-title", "input-title--required")}>
        {label}
      </span>
      <div className={cx("radio-choices")}>
        <AnimateSharedLayout>
          {!choices.lenght &&
            choices.map((choice, index) => (
              <span key={`${name}-${index}`} className={cx("radio-wrap")}>
                <input
                  type="radio"
                  name={name}
                  className={cx("radio-input")}
                  value={choice}
                  onChange={handleChoice}
                  checked={currentChoice === choice}
                  required={required}
                  id={`${name}-${index}`}
                />
                <label
                  htmlFor={`${name}-${index}`}
                  className={cx("radio-title")}
                >
                  {choice}
                </label>
                {currentChoice === choice && (
                  <motion.span
                    className={cx("radio-line")}
                    layoutId="line"
                  ></motion.span>
                )}
              </span>
            ))}
        </AnimateSharedLayout>
      </div>
    </label>
  )
}

Radio.propTypes = {}

export default Radio
