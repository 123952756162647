export const wrapVariants = {
  closed: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "afterChildren",
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
  open: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.07,
      delayChildren: 0.6,
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
}

export const statusVariants = {
  closed: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "afterChildren",
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
  open: {
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.07,
      delayChildren: 0.6,
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
}

export const statusTextVariants = {
  closed: {
    y: 40,
    opacity: 0,
  },
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.8,
    },
  },
}
