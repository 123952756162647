import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import { useInput } from "hooks/useInput"
import { Button, Input, Radio } from "components/Form"
import { motion, AnimatePresence } from "framer-motion"
import {
  wrapVariants,
  statusVariants,
  statusTextVariants,
  formItemVarians,
} from "./constants"
import Check from "components/Check"
import { FormItem } from "./FormItem"

const cx = classNames.bind(styles)

// const choices = ["50'000 - 70'000 EUR", "70'001 - 120'000 EUR"]

const Contacts = ({ className }) => {
  const { value: name, bind: bindName, reset: resetName } = useInput("")
  const { value: phone, bind: bindPhone, reset: resetPhone } = useInput("")
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("")
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  )

  const [error, setError] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [formOpen, setFormOpen] = React.useState(true)

  const url = "/mail.php"

  const handleRepeatFormFill = () => {
    setFormOpen(true)
    setError(false)
  }

  const handleFormClose = () => {
    setFormOpen(true)
    setSuccess(false)
    setError(false)
  }

  const handleOnInvalid = e => {
    e.target.setCustomValidity(" ")
    if (e.target.id === "phone") {
      e.target.setCustomValidity("Neteisingai įvestas telefono numeris")
    } else if (e.target.id === "email") {
      e.target.setCustomValidity("Neteisingai įvestas elektroninis paštas")
    } else {
      e.target.setCustomValidity("Prašome užpildyti reikalaujamus laukelius")
    }
  }

  const handleOnInput = e => {
    e.target.setCustomValidity("")
  }

  const handleSubmit = e => {
    e.preventDefault()

    // return

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        name: name,
        phone: phone,
        email: email,
        message: message,
      }),
    })
      .then(res => res.json())
      .then(data => {
        console.log("Success:", data)
        resetName()
        resetPhone()
        resetEmail()
        resetMessage()
        setError(false)
        setSuccess(true)
        setFormOpen(false)

        setTimeout(() => {
          handleFormClose()
        }, 2000)
      })
      .catch(error => {
        console.error("Error:", error)
        setSuccess(false)
        setError(true)
        setFormOpen(false)
      })
  }

  return (
    <motion.div
      variants={wrapVariants}
      initial="closed"
      exit="closed"
      animate="open"
      className={cx("contacts", className)}
    >
      <AnimatePresence>
        {!error && !success && (
          <motion.div
            className={cx("regular")}
            variants={statusVariants}
            animate="open"
            exit="closed"
          >
            <form onSubmit={handleSubmit}>
              <FormItem>
                <Input
                  name="name"
                  placeholder="Jūsų vardas"
                  bind={bindName}
                  label="Vardas:"
                />
              </FormItem>
              <FormItem>
                <Input
                  name="phone"
                  placeholder="+370..."
                  bind={bindPhone}
                  label="Telefono nr.:"
                  required
                  onInvalid={handleOnInvalid}
                  onInput={handleOnInput}
                />
              </FormItem>
              <FormItem>
                <Input
                  name="email"
                  placeholder="el@pastas.lt"
                  label="Jūsų el. paštas:"
                  type="email"
                  required
                  onInvalid={handleOnInvalid}
                  onInput={handleOnInput}
                  bind={bindEmail}
                />
              </FormItem>
              {/* <FormItem>
                <Radio
                  name="choice"
                  label="Dominantis sklypas (kaina):"
                  choices={choices}
                  defaultChoice={choices[0]}
                  required
                />
              </FormItem> */}

              <FormItem>
                <Input
                  textarea
                  name="message"
                  placeholder="Jūsų žinutė.."
                  label="Žinutė:"
                  bind={bindMessage}
                />
              </FormItem>
              <FormItem>
                <div className={cx("required-fields")}>
                  <span>*</span> - privalomas užpildyti laukas
                </div>
                <div className={cx("submit-wrap")}>
                  <Button type="submit" onClick={() => null}>
                    siųsti
                  </Button>
                </div>
              </FormItem>
            </form>
          </motion.div>
        )}
        {success && (
          <motion.div
            className={cx("success")}
            variants={statusVariants}
            initial="closed"
            key="success"
            animate="open"
            exit="closed"
          >
            <Check isChecked={success} />
            <motion.div
              variants={statusTextVariants}
              transition={{ delay: 0.8 }}
              className={cx("success-text")}
            >
              Žinutė išsiųsta sėkmingai!
            </motion.div>
          </motion.div>
        )}

        {error && (
          <motion.div
            className={cx("error")}
            initial="closed"
            variants={statusVariants}
            animate="open"
            exit="closed"
            key="error"
          >
            <motion.div
              variants={statusTextVariants}
              className={cx("error-text")}
            >
              Žinutės išsiųsti nepavyko! <br />
              Prašome bandyti &nbsp;
              <button
                className={cx("error-button")}
                onClick={handleRepeatFormFill}
              >
                dar kartą {error}
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default Contacts
